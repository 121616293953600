.root {
}

:global(.highContrast) .root {
  caret-color: var(--matterColorDark);
  color: var(--matterColorDark)
}

.input {
  border: 2px solid #DDDDDD;
  border-radius: 15px;
  width: 140px;
  padding: 5px 10px;
  font-size: 14px;
/*border-bottom-color: var(--attentionColor);*/
  &:hover {
    border-bottom-color: #DDDDDD;
   }
  &:focus {
    border-bottom-color: #DDDDDD;
   }
}

.customError {
  position: absolute;
}

.inputSuccess {
  /*border-bottom-color: var(--successColor);*/
}

.inputError {
  border-bottom-color: var(--failColor);
}

.textarea {
}

.inputContainer {
  position: relative;
}

.rightIcon {
  position: absolute;
  top: 50%;
  font-size: 18px;
  right: 40px;
  transform: translateY(-50%);
}


