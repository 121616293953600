@import '../../styles/customMediaQueries.css';

.root {
  padding: 20px 14px;
  background: var(--colorWhite);
  max-width: 100%;
  margin: 0 auto;
  border-radius: 12px;
  order: 2;

  @media (--viewportLargeWithPaddings) {
    order: 1;
    max-width: 402px;
    margin: 0 0 0 36px;
    box-shadow: 4px 4px 12px 0 #00000040;
    position: absolute;
    z-index: 8;
    right: 10%;
    top: 50%;
    transform: translateY(-50%);

  }

  @media (--viewportLarge) {
    max-width: 500px;
    padding: 50px 46px 40px;
  }
}

.title {
  margin: 0 0 15px;
  font-size: 25px;
  line-height: 1.2;
  color: var(--marketplaceColor);
  font-weight: var(--fontWeightBold);

  @media (--viewportMedium) {
    font-size: 30px;
    margin: 0 0 10px;
    line-height: 40px;
  }
}

.subTitle {
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
  margin-top: 10px;
  color: var(--textColor);

  @media (--viewportMedium) {
    font-size: 24px;
    line-height: 30px;
  }
}

.advancedSearchLocation {

}

.advancedSearchLocationCurrent {

  & input::placeholder {
    color: var(--matterColorDark);
  }
}

.locationHolder {
  position: relative;
  margin-bottom: 16px;

  &:before {
    content: '';
    position: absolute;
    z-index: 10;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    background: transparent url("./images/location.svg") 0 0 no-repeat;
    width: 20px;
    height: 24px;
  }
}

.locationAutocompleteInput {
  padding: 0 20px 0 50px;
  min-height: 60px;
  line-height: 1;
  border: 1px solid var(--borderColor);
  border-radius: 12px;

  &::placeholder {
    color: var(--customGray);
  }
}

.selectCategoryHolder {
  position: relative;
  margin-bottom: 16px;

  &:before {
    content: '';
    position: absolute;
    z-index: 10;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    background: transparent url("./images/service.svg") 0 0 no-repeat;
    width: 23px;
    height: 24px;
  }
}

.selectCategory {
  border: 1px solid var(--borderColor);
  border-radius: 12px;
  height: 60px;
  padding: 0 20px 0 50px;
  box-shadow: none;
}

.locationAutocompleteInputIcon {
  display: none;
}

.searchFooter {
  margin: 20px 0 0;
}

.dates {
  width: 100%;
  margin-top: 15px;

  @media (--viewportMedium) {
    margin-top: 20px;
  }

  & > div,
  & > div > div {
    display: block;
  }

  & [class*="PopupOpenerButton_label"] {
    width: 100%;
    min-height: 40px;
    text-align: left;
    border: 1px solid var(--customGrayBorderAlt);
    padding: 0 24px 0 20px;
    margin: 0;

    /* Background */
    background-image: url("data:image/svg+xml,%3Csvg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4.68579 5.31455C4.86 5.48788 5.14 5.48788 5.31421 5.31455L8.86967 1.75909C9.04344 1.58487 9.04344 1.30399 8.86967 1.13066C8.6959 0.956446 8.41457 0.956446 8.24124 1.13066L5 4.37146L1.75876 1.13066C1.58498 0.956446 1.30366 0.956446 1.13033 1.13066C0.956557 1.30399 0.956557 1.58487 1.13033 1.75909L4.68579 5.31455Z' stroke='%232D2C35'/%3E%3C/svg%3E%0A");
    background-position: center right 16px;
    background-size: 12px;

    font-size: 16px;
    color: var(--customGray);

    @media (--viewportMedium) {
      font-size: 14px;
    }

    &:hover,
    &:focus {
      border: 1px solid var(--customBlack);
    }
  }

  & [class*="FilterPopup_popup"] {
    min-width: 100%;
    margin: 0;
    z-index: 1;
    overflow: hidden;

    @media (--viewportMedium) {
      min-width: 360px;
    }
  }

  & [class*="DateRangeController_inputRoot"] {
    margin-top: 0;
    margin-bottom: 0;
  }

  & [class*="FilterForm_buttonsWrapper"] {
    background: var(--marketplaceColor);
    justify-content: space-between;

    & button {
      color: var(--colorWhite);

      &:hover {
        color: var(--marketplaceColorDark);
      }
    }
  }
}

.datesActive {

  & [class*="PopupOpenerButton_label"] {
    color: var(--customBlack);
  }

  & [class*="PopupOpenerButton_labelSelected"] {
    width: 100%;
    text-align: left;
    border: 1px solid var(--customGrayBorderAlt);
    background-color: transparent;

    &:hover,
    &:focus {
      border: 1px solid var(--customBlack);
    }
  }
}

.datesHolder {
  position: relative;
  width: 100%;
  margin-bottom: 20px;
  height: 60px;

  &:before {
    content: '';
    position: absolute;
    z-index: 10;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    background: transparent url("./images/calendar.svg") 0 0 no-repeat;
    width: 23px;
    height: 24px;
  }
}

.menuLabel {
  /* Dimensions */
  width: 100%;
  background-color: var(--colorWhite);

  /* Borders */
  border-radius: var(--borderRadiusBig);
  outline: none;

  /* Transition */
  transition: all ease-in-out 100ms;

  padding: 0 20px 0 50px;
  /* box-shadow: 0 2px 3px 0 rgba(216, 220, 230, 0.7); */

  /* Unset user agent styles */
  appearance: none;

  /* Background */
  background-image: url("data:image/svg+xml,%3Csvg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4.68579 5.31455C4.86 5.48788 5.14 5.48788 5.31421 5.31455L8.86967 1.75909C9.04344 1.58487 9.04344 1.30399 8.86967 1.13066C8.6959 0.956446 8.41457 0.956446 8.24124 1.13066L5 4.37146L1.75876 1.13066C1.58498 0.956446 1.30366 0.956446 1.13033 1.13066C0.956557 1.30399 0.956557 1.58487 1.13033 1.75909L4.68579 5.31455Z' stroke='%232D2C35'/%3E%3C/svg%3E%0A");
  background-position: center right 16px;
  background-size: 12px;

  cursor: pointer;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;

  color: var(--textColor);

  font-weight: var(--fontWeightRegular);
  font-size: 18px;
  line-height: 60px;
  height: 60px;
  margin-bottom: 10px;
  border: 1px solid var(--borderColor);


  @media (--viewportMedium) {
    /*padding: 0 32px 0 16px;*/
    /*font-size: 14px;*/
  }

  &:hover {
    /*border: 1px solid var(--customBlack);*/
    /* transition: all ease-in-out 100ms; */
  }
  &:focus {
    background-color: var(--colorWhite);
    /*border-color: var(--customBlack);*/
    /* transition: all ease-in-out 100ms; */
  }
  &:disabled {
    opacity: 0.5;

    &:hover {
      cursor: not-allowed;
    }
  }
}

.menuLabelActive {
  color: var(--customBlack);
}

.categoryMenuContent {
  display: none;
  position: absolute;
  left: 0;
  top: 66px;
  width: 100%;
  background: var(--colorWhite);
  padding: 20px 20px;
  box-shadow: var(--boxShadowPopupLight);
  border-radius: 0 0 var(--borderRadiusMedium) var(--borderRadiusMedium);
  z-index: 1;
}

.open {
  position: relative;

  & .menuLabel {
    border-color: var(--customBlack);
    border-radius: var(--borderRadiusMedium) var(--borderRadiusMedium) 0 0;
  }

  & .categoryMenuContent {
    display: block;
  }
}

.closed {

}

.categoryItems {

}

.categoryItem {
  display: block;
  margin-bottom: 20px;
}

.categoryButtons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.categoryButtonsItem {
  font-size: 14px;
  line-height: 16px;
  font-weight: var(--fontWeightMedium);
  cursor: pointer;

  &:hover,
  &:focus {
    color: var(--marketplaceColor);
  }

  &:last-of-type {
    margin-left: 19px;
  }
}

.submitButton {
  min-height: 60px;
  font-size: 18px;
  font-weight: 700;
  line-height: 30px;
  border-radius: 12px;


  & span {
    position: relative;
    &:before {
      content: '';
      position: absolute;
      z-index: 10;
      left: -40px;
      top: 50%;
      transform: translateY(-50%);
      background: transparent url("./images/search.svg") 0 0 no-repeat;
      width: 30px;
      height: 30px;
    }
  }
}

.fieldDatePicker {

}

.fieldDateInput {

}

.fieldDatePopup {
  position: absolute;
  width: 100%;
  z-index: 100;
}

.placeHolder {
  background-image: none !important;
  width: 100%;
  background-color: var(--colorWhite);
  border-radius: var(--borderRadiusBig);
  outline: none;
  transition: all ease-in-out 100ms;
  padding: 0 20px 0 50px !important;
  appearance: none;
  cursor: pointer;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  color: var(--textColor);
  font-weight: var(--fontWeightRegular);
  font-size: 16px;
  line-height: 60px;
  height: 60px;
  margin-bottom: 10px;
  border: 1px solid var(--borderColor);

  &::placeholder {
    color: var(--textColor);
  }
}
