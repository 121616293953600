@import '../../styles/customMediaQueries.css';

.root {
  /* Layout */
  display: flex;
  flex-direction: column;


  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }
}

.isBackgroundChecked {
  position: relative;

  &:after {
    content: 'Background Check Verified';
    background: rgba(212, 38, 163, 0.7);
    position: absolute;
    top: 14px;
    right: 15px;
    width: 200px;
    height: 30px;
    border-radius: 8px;
    font-size: 12px;
    font-weight: var(--fontWeightBold);
    line-height: 22px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.aspectRatioWrapper {
  transition: var(--transitionStyleButton);
  background: var(--colorGrey100); /* Loading BG color */
  border-radius: var(--borderRadiusMedium);

  @media (--viewportMedium) {
    &:hover {
      transform: scale(1.02);
      box-shadow: var(--boxShadowListingCard);
    }
  }
}

.rootForImage {
  /*border: solid 1px var(--textColor);*/
  width: 100%;
  height: 100%;
}

.info {
  /* Layout */
  display: flex;
  flex-direction: column;
  padding: 30px 20px 2px 20px;
  flex-grow: 1;
  justify-content: space-between;
}

.price {
  /* Layout */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-shrink: 0;
  margin-bottom: 4px;
}

.priceValue {
  /* Font */
  composes: textSmall from global;
  font-weight: var(--fontWeightBold);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;
  font-size: 20px;
  line-height: 26px;
  color: var(--marketplaceColor);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    /*font-size: 24px;*/
    line-height: 30px;
  }
}

.perUnit {
  /* Font */
  composes: textSmall from global;
  font-weight: var(--fontWeightBold);

  color: var(--textColor);
  display: inline-flex;
  align-items: center;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 5px;
  font-size: 16px;
  line-height: 20px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 18px;
    line-height: 22px;
  }
}

.mainInfo {
  display: flex;
  flex-direction: column;
}

.title {
  /* Font */
  font-weight: var(--fontWeightBold);
  margin: 0;
  font-size: 18px;
  line-height: 29px;
  color: var(--marketplaceColor);
}

.authorInfo {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  font-weight: var(--fontWeightBold);
  color: var(--marketplaceColor);

  padding-top: 4px;
  padding-bottom: 4px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  font-size: 20px;
  line-height: 26px;

  @media (--viewportMedium) {
    font-size: 24px;
    line-height: 30px;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.categoryHolder {
  margin-bottom: 10px;
}

.category {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: var(--textColor);
}

.locationHolder {
  position: relative;
  padding: 10px 0 10px 30px;
  line-height: 17px;

  &:after {
    content: '';
    position: absolute;
    z-index: 0;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 21px;
    height: 23px;
    background: transparent url("./images/location.svg") 0 0 no-repeat;
  }

}

.location {
  font-size: 14px;
  font-weight: var(--fontWeightRegular);
  line-height: 17px;
  color: var(--textColor);
}
